import React from "react";
import "./starsBackground.css";

const StarsBackground = () => {
  return (
    <div id="space">
      <div className="stars"></div>
      <div className="stars"></div>
      <div className="stars"></div>
      <div className="stars"></div>
      <div className="stars"></div>
    </div>
  );
};

export default StarsBackground;
