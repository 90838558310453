import Navbar from "./Composant/Navbar/navbar";
import HomePage from "./Section/1- Home/homePage";
import AboutSection from "./Section/2- A propos/a_propos";
import ServiceSection from "./Section/3- Services/serviceSection";
import SkillSection from "./Section/4- Skills/skills";
import PortfolioSection from "./Section/5- Portfolio/portfolio";
import ContactSection from "./Section/6- Contact/contact";

function Base() {
  return (
    <>
      <Navbar></Navbar>
      <HomePage></HomePage>
      <AboutSection></AboutSection>
      <ServiceSection></ServiceSection>
      <SkillSection></SkillSection>
      <PortfolioSection></PortfolioSection>
      <ContactSection></ContactSection>
    </>
  );
}

export default Base;
