import React, { useState } from "react";
import QRCode from "qrcode.react";
import "./qrCode.css";
import BackButton from "../../Composant/Back_Button/back_button.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

function QRCodeGenerator() {
  const [userInput, setUserInput] = useState("");
  const [format, setFormat] = useState("png");
  const [generated, setGenerated] = useState(false);

  const handleGenerate = () => {
    setGenerated(true);
  };

  const handleDownload = () => {
    const canvas = document.getElementById("qrcode");
    const dataURL = canvas.toDataURL(`image/${format}`);
    const link = document.createElement("a");
    link.href = dataURL;
    link.download = `${userInput}-qrcode.${format}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <section className="section-qrCode">
      <BackButton />
      <p className="title_qrCode">QRcode Generator 📷</p>
      <div className="grp-all">
        <div className="grp-process">
          <div className="box-text">
            <h2>Fonctionnement du générateur :</h2>
            <ul className="liste-qrCode">
              <li>Saisir l'adresse URL du lien souhaité</li>
              <li>Choisir le format d'image voulu (SVG ou PNG)</li>
              <li>Cliquez sur le bouton de generation</li>
              <li>Télécharger le QRCode </li>
            </ul>
          </div>

          <div className="box-input-user-qrcode">
            <div className="input-user-qr-code">
              <label>Lien cible : </label>
              <input
                type="text"
                value={userInput}
                onChange={(e) => setUserInput(e.target.value)}
              />
            </div>
            <div className="input-user-qr-code">
              <label>Format d'images :</label>
              <select
                value={format}
                onChange={(e) => setFormat(e.target.value)}
              >
                <option value="png">PNG</option>
                <option value="svg">SVG</option>
              </select>
            </div>
            <div>
              <button className="button-qrCode" onClick={handleGenerate}>
                Generate QR Code
              </button>
            </div>
          </div>
        </div>

        {generated && (
          <div className="box-qrCode">
            <QRCode id="qrcode" value={userInput} size={256} />
            <button className="button-qrCode" onClick={handleDownload}>
              <FontAwesomeIcon icon={faDownload} bounce />
              Téléchargement du QR Code
            </button>
          </div>
        )}
      </div>
    </section>
  );
}

export default QRCodeGenerator;
