import "./contact.css";
import emailjs from "@emailjs/browser";
import TitleSection from "../../Composant/TitleSection/titleSection";
import Footer from "../../Composant/Footer/footer";
import React, { useRef, useState } from "react";
import { toast } from "react-toastify";

function ContactSection() {
  const form = useRef();

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);

    // Validation de l'adresse e-mail
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!emailPattern.test(newEmail)) {
      setEmailError("Veuillez saisir une adresse e-mail valide.");
    } else {
      setEmailError("");
    }
  };

  const sendEmail = (e) => {
    e.preventDefault();

    const nom_prenom = form.current[0].value;
    const objet = form.current[1].value;
    const email = form.current[2].value;
    const message = form.current[3].value;

    toast.info("Envoi de l'e-mail en cours...");

    emailjs
      .send(
        "service_sav29o4",
        "template_gahmvdo",
        {
          nom_prenom: nom_prenom,
          objet: objet,
          email: email,
          message: message,
        },
        "Ns9nPfj-zRIkrzi8n"
      )
      .then(
        (result) => {
          console.log(result.text);
          toast.success("E-mail envoyé avec succès!");
        },
        (error) => {
          console.log(error.text);
          toast.error("Erreur lors de l'envoi du mail");
        }
      );
  };

  return (
    <>
      <section className="section_contact" id="section_contact">
        <TitleSection title="Contact" progress="100"></TitleSection>

        <form className="form_contact" ref={form} onSubmit={sendEmail}>
          <label className="label-form-contact">Nom, Prénom :</label>
          <input
            required
            className="input"
            type="text"
            name="nom_prenom"
            placeholder="Nom de famille, Prénom"
            minLength={5}
          />

          <label className="label-form-contact">Objet :</label>
          <input
            required
            className="input"
            type="text"
            name="objet"
            placeholder="Sujet du message"
            minLength={5}
          />

          <label className="label-form-contact">Email :</label>
          <input
            className="input"
            type="email"
            name="email"
            value={email}
            onChange={handleEmailChange}
            required
            pattern="[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}"
            placeholder="Adresse Email"
          />
          {emailError && <span className="error_email">{emailError}</span>}

          <label className="label-form-contact">Message :</label>
          <textarea
            required
            className="input_textarea"
            name="message"
            placeholder="Message"
            minLength={10}
          ></textarea>

          <button className="button_form" type="submit">
            <div className="svg-wrapper-1">
              <div className="svg-wrapper">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path
                    fill="currentColor"
                    d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"
                  ></path>
                </svg>
              </div>
            </div>
            <span>Envoyer</span>
          </button>
        </form>
        <Footer></Footer>
      </section>
    </>
  );
}

export default ContactSection;
