class Participants {
    constructor(nomPrenom = "", conjoint = "") {
      this.nomPrenom = nomPrenom;
      this.conjoint = conjoint;
    }
  
    toString() {
      return `Nom : ${this.nomPrenom}, Conjoint : ${this.conjoint}`;
    }
  }
  
  export default Participants;
  