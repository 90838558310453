import "./portfolio.css";
import TitleSection from "../../Composant/TitleSection/titleSection";
import PORTFOLIODETAILSPROJETCS from "./portfolio_projects_details.json";
import MediaRessources from "../../ressources/media-ressources";

function PortfolioSection() {
  return (
    <section className="section_portfolio" id="section_portfolio">
      <TitleSection title="Portfolio" progress="75"></TitleSection>

      <div className="boxs-portfolio">
        {PORTFOLIODETAILSPROJETCS.portfolio_detail.map((card) => (
          <div className="box-portfolio">
            <a href={"portfolio/" + card.id}>
              <img
                className="img-portfolio"
                src={Object.values(MediaRessources.listImagePortfolio)[card.id]}
                alt={card.alt}
              />
            </a>
            <div className="texte-img-portfolio">{card.titre}</div>
          </div>
        ))}
      </div>

      <div className="link_notion">
        Si vous voulez en savoir plus sur mes expériences professionelles, voici
        un lien vers mon{" "}
        <a
          href="https://sphenoid-honeydew-2e6.notion.site/Portfolio-5d905ad1502e43eb8d7bec5f4fc5939f?pvs=4"
          style={{ color: "white" }}
        >
          Notion.
          <img
            src={MediaRessources.notion}
            style={{ width: "20px" }}
            alt="Lien vers une Page Notion de Anthony CORNILLEAU du nom de Portfolio."
          ></img>
        </a>
      </div>
    </section>
  );
}

export default PortfolioSection;
