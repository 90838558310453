import "./back_button.css";
import { useNavigate, useLocation } from "react-router-dom";
import MediaRessources from "../../ressources/media-ressources";

/* Voir pour le retour ciblé */
function Back_Button() {
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location.key)

  const handleGoBack = () => {
    // Si une navigation précédente existe dans l'application, revenir en arrière
    if (location.key !== "default") {
      navigate(-1);
    } else {
      // Sinon rediriger vers la base du site
      window.location.href = window.location.origin;
    }
  };

  return (
    <>
      <button
        className="button-backdown"
        to="/#section_portfolio"
        onClick={handleGoBack}
      >
        <img
          src={MediaRessources.chevronDown}
          width={30}
          height={30}
          alt="Icone de flèche en direction de la gauche pour signifier un retour à la page principal"
        />
        Retour
      </button>
    </>
  );
}

export default Back_Button;
