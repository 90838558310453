import "./footer.css";

function Footer() {
  
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <p>
        Designed by @Léa Vaudandaine
        <br />
        Made By <span>CORNILLEAU Anthony</span> in 2023 - {currentYear}
        <br />
        Copyright all right reserved -{" "}
        <a href="/mentions-legales" style={{ color: "white" }}>
          Mentions Légales
        </a>
      </p>
    </footer>
  );
}

export default Footer;
