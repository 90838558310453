import image_about from "../ressources/images/profil.webp";
/* Icons */
import logo from "../ressources/logos/logo.svg";
import chevronDown from "../ressources/logos/chevron-down.svg";
import x from "../ressources/logos/x.svg";
import iconeMenuOpen from "../ressources/logos/burger_icone.svg";
import iconeMenuClose from "../ressources/logos/x.svg";
import traitService from "../ressources/logos/trait-service.webp";

/* Réseaux */
import linkedin from "../ressources/logos/linkedin.svg";
import github from "../ressources/logos/github.svg";

/* Techno */
import angular from "../ressources/logos/angular.svg";
import java from "../ressources/logos/java.svg";
import javascript from "../ressources/logos/javascript.svg";
import html5 from "../ressources/logos/html5.svg";
import laravel from "../ressources/logos/laravel.svg";
import php from "../ressources/logos/php.svg";
import python from "../ressources/logos/python.svg";
import react from "../ressources/logos/react.svg";
import springBoot from "../ressources/logos/spring-boot.svg";
import symfony from "../ressources/logos/symfony.svg";
import wordpress from "../ressources/logos/wordpress.svg";

/* BDD */
import mariaDb from "../ressources/logos/mariaDb.svg";
import mySql from "../ressources/logos/mySql.svg";
import postgre from "../ressources/logos/postgre.svg";

/* Prod */
import notion from "../ressources/logos/notion.svg";

/* Fond d'écran */
import homeDesktop from "../ressources/images/background/home-desktop.webp";
import homeMobile from "../ressources/images/background/home-mobile.webp";
import otherDesktop from "../ressources/images/background/others-desktop.webp";
import otherMobile from "../ressources/images/background/home-mobile.webp";

/* Loisirs - Images */
import boxeThai from "../ressources/images/Loisirs/BoxeThai.webp";
import play from "../ressources/images/Loisirs/Play.webp";
import kayak from "../ressources/images/Loisirs/kayak.webp";
import manga from "../ressources/images/Loisirs/manga.webp";
import yourName from "../ressources/images/Loisirs/your-name.webp";

/* Loisirs - Logo */
import ico_boxe from "../ressources/images/Loisirs/logo/boxe.svg";
import ico_film from "../ressources/images/Loisirs/logo/film.svg";
import ico_jeuVideo from "../ressources/images/Loisirs/logo/jeuxVideo.svg";
import ico_kayak from "../ressources/images/Loisirs/logo/kayak.svg";
import ico_lecture from "../ressources/images/Loisirs/logo/lecture.svg";

/* Portfolio - Détails */
import mockupPaniers from "../ressources/images/mockup-paniers.webp";
import mockupBibi from "../ressources/images/mockup-bibiproject.png";

/* Vidéo */
import web from "../ressources/video/web.webm";
import idee from "../ressources/video/idee.webm";
import referencement from "../ressources/video/referencement.webm";
import maintenance from "../ressources/video/maintenance.webm";

/* Portfolio - Demo Projet */
import pere_noel from "../ressources/images/pere_noel.webp";
import casse_brique from "../ressources/images/Portfolio/casse-brique.webp";
import folder from "../ressources/images/Portfolio/folder.webp";
import password from "../ressources/images/Portfolio/password.webp";
import qrcode from "../ressources/images/Portfolio/qrcode.webp";
import rename from "../ressources/images/Portfolio/rename.webp";
import santa from "../ressources/images/Portfolio/santa.webp";

const MediaRessources = {
  image_about: image_about,
  /* Icons */
  logo: logo,
  chevronDown: chevronDown,
  x: x,
  iconeMenuOpen: iconeMenuOpen,
  iconeMenuClose: iconeMenuClose,
  traitService: traitService,
  /* Réseaux */
  linkedin: linkedin,
  github: github,
  /* Techno */
  angular: angular,
  java: java,
  javascript: javascript,
  html5: html5,
  laravel: laravel,
  php: php,
  python: python,
  react: react,
  springBoot: springBoot,
  symfony: symfony,
  wordpress: wordpress,
  mariaDb: mariaDb,
  mySql: mySql,
  postgre: postgre,
  /* Prod */
  notion: notion,
  /* Fond d'écran */
  homeDesktop: homeDesktop,
  homeMobile: homeMobile,
  otherDesktop: otherDesktop,
  otherMobile: otherMobile,
  /* Images Loisirs */
  BoxeThai: boxeThai,
  Play: play,
  kayak: kayak,
  manga: manga,
  yourName: yourName,
  /* Icones Loisirs */
  ico_boxe: ico_boxe,
  ico_film: ico_film,
  ico_jeuVideo: ico_jeuVideo,
  ico_kayak: ico_kayak,
  ico_lecture: ico_lecture,
  /* Mockup */
  mockupPaniers: mockupPaniers,
  mockupBibi: mockupBibi,

  /* Video */
  video_web: web,
  video_referencement: referencement,
  video_maintenance: maintenance,
  video_idee: idee,

  pere_noel: pere_noel,

  /* Portfolio - Demo Projet */
  /* ⚠️ Attention l'ordre est important ⚠️*/
  listImagePortfolio: {
    bibi_project: mockupBibi,
    santa: santa,
    qrcode: qrcode,
    paniers: mockupPaniers,
    casse_brique: casse_brique,
    password: password,
    folder: folder,
    rename: rename,
  },
};

export default MediaRessources;
