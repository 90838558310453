import "./homePage.css";
import Button from "../../Composant/Button/button";
import StarsBackground from "../../Composant/Stars/starsBackground";

function HomePage() {
  return (
    <>
      <section className="section_home">
        <StarsBackground />
        <div className="box_home">
          <div className="title_home">
            <h1 className="animate__animated animate__bounceInDown">
              <strong>CORNILLEAU Anthony</strong> <br /> Développeur web
            </h1>
          </div>
          <div className="box_home_text_button">
            <div className="text_home">
              <p>
                Bienvenue sur mon portfolio, je suis Anthony Cornilleau
                développeur web basé sur <strong>Nantes</strong>.
              </p>

              <p>
                Je suis passionné par le développement web depuis de nombreuses
                années. J'aime créer des sites web et des applications mobiles
                qui sont à la fois fonctionnels et esthétiques.
              </p>

              <p>
                Sur ce site, vous pouvez découvrir mes projets les plus récents.
              </p>

              <p>
                Je vous invite à parcourir mes réalisations et à me contacter si
                vous avez des questions ou si vous souhaitez travailler avec
                moi.
              </p>
            </div>
            <Button link={"#section_about"} text={"A Propos"}></Button>
          </div>
        </div>
      </section>
    </>
  );
}

export default HomePage;
